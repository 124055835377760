// eslint-disable-next-line @typescript-eslint/no-namespace
import {BandmanagerRestApiProfileDto} from "@digitale-menschen/bandmanager-rest-api";

export namespace UserStateActions {

  export class SetEmail {
    static readonly type = '[User] SetEmail';

    constructor(public email: string) {
    }
  }

  export class SetIsLoggedIn {
    static readonly type = '[User] SetIsLoggedIn';

    constructor(public isLoggedIn: boolean) {
    }
  }

  export class SetProfile {
    static readonly type = '[User] SetProfile';

    constructor(public profile: BandmanagerRestApiProfileDto) {
    }
  }
}
